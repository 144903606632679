<template>
  <div class="terms-of-service">
    <div class="hero is-medium">
      <div class="hero-body">
        <div class="columns is-multiline">
          <div class="column is-8 is-offset-2">
            <simsed-logo width="48" font-size="1.5em" />
            <h1 class="has-text-centered">
              School information management application
            </h1>
          </div>
          <div class="column is-8 is-offset-2">
            <h2 class="title is-4">Terms of service</h2>
            <p class="subtitle is-6 has-text-justified">
              By accessing this Website, accessible from https://simsed.com, you
              are agreeing to be bound by these Website Terms and Conditions of
              Use and agree that you are responsible for the agreement with any
              applicable local laws. If you disagree with any of these terms,
              you are prohibited from accessing this site. The materials
              contained in this Website are protected by copyright and trade
              mark law.
            </p>
            <h3 class="title is-5 mt-2">Use License</h3>
            <p class="subtitle is-6 has-text-justified">
              Permission is granted to use Simsed as medium to manage and
              administer your school's operation. This is the grant of a
              license, not a transfer of title, and under this license you may
              not:
            </p>
            <h3 class="title is-5 mt-2">Administrators</h3>
            <p class="subtitle is-6 has-text-justified">
              Terms for administrators
            </p>
            <h3 class="title is-5 mt-2">Educators</h3>
            <p class="subtitle is-6 has-text-justified">Terms for educators</p>
            <h3 class="title is-5 mt-2">Students</h3>
            <p class="subtitle is-6 has-text-justified">Terms for students</p>
            <h3 class="title is-5 mt-2">Parents</h3>
            <p class="subtitle is-6 has-text-justified">Terms for parents</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SimsedLogo from '../components/SimsedLogo'

export default {
  data() {
    return {}
  },
  components: {
    SimsedLogo,
  },
}
</script>
